<template>
    <b-container fluid class="dashboard-container pt-4">
        <b-row class="header-row">
            <b-col
                lg="9"
                class="d-flex flex-column align-items-stretch justify-content-between flex-lg-row align-items-lg-center"
            >
                <GreetUser class="mb-2 mb-lg-0" />

                <div
                    class="d-flex flex-column align-items-start justify-content-end flex-grow flex-sm-row align-items-sm-center ml-lg-4"
                >
                    <b-input-group style="min-width: 19em" class="mb-2 mb-sm-0">
                        <b-input-group-prepend is-text @click="searchChanged">
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                            type="search"
                            v-model="search"
                            @search="searchChanged"
                            :placeholder="t('notificationList_SearchPlaceholder')"
                        ></b-form-input>
                    </b-input-group>
                    <b-button
                        variant="primary"
                        to="NewPatient"
                        class="no-wrap ml-sm-3"
                        v-if="
                            checkPermissions({
                                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
                            })
                        "
                    >
                        <div><b-icon-plus class="mr-2" /> {{ t('addNewPatient') }}</div>
                    </b-button>
                </div>
            </b-col>
            <b-col lg="3" class="d-flex align-items-center">
                <h2 class="text-primary-light mb-0 text-break d-none d-lg-block">
                    {{ t('announcements') }}
                </h2>
            </b-col>
        </b-row>
        <b-row>
            <!-- MAIN COL - HEADER -->
            <b-col lg="9" class="mb-4">
                <ReservationNotifications
                    ref="reservationNotifications"
                    @change-status="setNotificationStatus($event, 'reservationNotifications')"
                />
                <OrderNotifications
                    v-if="hasOrderingPermission"
                    ref="orderNotifications"
                    @change-status="setNotificationStatus($event, 'orderNotifications')"
                />
            </b-col>

            <!-- SIDE COL - CONTENT -->
            <b-col lg="3">
                <b-row class="d-lg-none header-row">
                    <b-col>
                        <h2 class="text-primary-light mb-0 text-break">{{ t('announcements') }}</h2>
                    </b-col>
                </b-row>

                <b-row
                    v-for="annoucement in announcements"
                    :key="annoucement.timestamp"
                    class="ann"
                >
                    <b-col cols="12">
                        <b-card class="card-m-h mb-4">
                            <h5 class="text-caption ann-title-fontsize">
                                <a :href="annoucement.url" target="_blank">
                                    {{ annoucement.title }}
                                </a>
                            </h5>
                            <div class="ann-body-overflow-y">
                                <ImagePlaceholder class-name="ann-image" :src="annoucement.image">
                                    <img class="ann-image" src="@/assets/placeholder.png" />
                                </ImagePlaceholder>
                                <div v-html="annoucement.body"></div>
                            </div>
                            <div class="ann-date">
                                <small>{{ annoucement.displayDate | date }}</small>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <p class="text-right font-weight-lighter">
            <small>
                <span class="float-left"> {{ t('disclaimer') }}</span>
                WebApp {{ appVersion }}, ORION API V{{ apiVersion }}, {{ buildTimeStamp
                }}{{ calculatorUrl }}
            </small>
        </p>
    </b-container>
</template>

<script>
import isNil from 'lodash/isNil';
import {mapState, mapGetters} from 'vuex';
import version from '@/assets/version.json';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import GreetUser from '@/components/GreetUser.vue';
import ImagePlaceholder from '@/components/ImagePlaceholder.vue';
import ReservationNotifications from './components/reservationnotifications/Index.vue';
import OrderNotifications from './components/ordernotifications/Index.vue';
import {CALCULATOR_API} from '@/config';

const NOTIFICATION_STATUS = {
    INVALID: 'Invalid',
    UNREAD: 'Unread',
    READ: 'Read',
    DISMISSED: 'Dismissed',
};

export default {
    components: {GreetUser, ReservationNotifications, OrderNotifications, ImagePlaceholder},
    name: 'Dashboard',
    data() {
        return {
            PERMISSIONS,
            PERMISSIONS_VALUES,
            notifications: [],
            appVersion: null,
            search: null,
            announcements: [],
        };
    },
    computed: {
        ...mapState({
            apiVersionInfo: (state) => state.apiversion.apiVersionInfo,
        }),
        ...mapGetters('user', ['currentUser', 'activeCustomerId']),
        ...mapGetters('permissions', ['permissions']),

        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        apiVersion() {
            if (this.apiVersionInfo && this.apiVersionInfo.version)
                return this.apiVersionInfo.version;

            return '';
        },

        calculatorUrl() {
            if (CALCULATOR_API.BASE_URL.includes('develop')) {
                return '';
            }
            if (CALCULATOR_API.BASE_URL.includes('sandbox')) {
                return ', SANDBOX';
            } else {
                return ', ' + CALCULATOR_API.BASE_URL;
            }
        },

        buildTimeStamp() {
            if (this.apiVersionInfo && this.apiVersionInfo.buildTimeStamp)
                return this.apiVersionInfo.buildTimeStamp;

            return '';
        },
    },
    watch: {
        async activeCustomerId(value) {
            if (!isNil(value)) {
                await Promise.all([this.fetchAnnouncements()]);
            }
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser?.accessPermissions
            );
        },
        async setNotificationStatus(item, refKey) {
            if (item.status === NOTIFICATION_STATUS.READ) return;
            try {
                this.setSpinner(true);
                const {success, error} = (
                    await this.$http.post(`notifications/${item.userNotificationId}`, {
                        status: NOTIFICATION_STATUS.READ,
                    })
                ).data;
                if (!success) throw error;
                await this.$refs[refKey].refreshRecords(1);
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            } finally {
                this.setSpinner(false);
            }
        },
        async fetchAnnouncements() {
            try {
                const {success, error, results} = (await this.$http.get(`announcements`)).data;
                if (!success) throw error;
                this.announcements = results;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
        async searchChanged() {
            ['reservationNotifications', 'orderNotifications'].forEach(async (refKey) => {
                this.$refs[refKey].queryParams.search = this.search;
                await this.$refs[refKey].refreshRecords(1);
            });
        },
    },
    async mounted() {
        this.search = this.$route.query['res-search'] || this.$route.query['ord-search'];
        // Print out the front end version to the console everytime the user navigates to the dashboard
        this.appVersion = version.info;
        console.log('WebApp ' + version.info);

        /* Print out the backend API version to the console everytime the user navigates to the dashboard.
        If getter does not yet have the backend info from the asynchronous call, don't try to print it out.
        */
        if (this.apiVersionInfo) {
            console.log('API V' + this.apiVersionInfo.version, this.apiVersionInfo.buildTimeStamp);
        } else {
            await this.fetchApiInfo();
        }

        // Fetch the notifications if an active customer has been set
        if (!isNil(this.activeCustomerId)) {
            await Promise.all([this.fetchAnnouncements()]);
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables';

::v-deep .ann {
    .ann-body-overflow-y {
        max-height: 220px;
        overflow-y: auto;
    }

    .ann-title-fontsize {
        font-size: medium;
    }

    .ann-image {
        height: 100px;
        max-width: 173px;
        float: left;
        margin-right: 15px;
    }

    .ann-date {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    @media (min-width: 992px) and (max-width: 1574px) {
        .ann-body-overflow-y {
            max-height: 180px;
        }
    }
}
</style>
