<template>
    <b-dropdown
        id="dropdown-form"
        :text="t('filters')"
        variant="secondary"
        ref="dropdown"
        class="mr-2"
        boundary="window"
        @show="formStateCatch"
        @hide="filterCatch"
    >
        <b-dropdown-form @submit.prevent="apply">
            <b-form-group :label="t('createdBy')" label-for="dropdown-form-createdby">
                <b-form-input
                    id="dropdown-form-createdby"
                    :state="filters.createdBy ? true : null"
                    :placeholder="t('createdBy')"
                    v-model="filters.createdBy"
                ></b-form-input>
            </b-form-group>

            <b-form-row>
                <b-col>
                    <b-button variant="secondary" @click="cancel"> {{ t('cancel') }}</b-button>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    <b-button
                        class="mr-2"
                        variant="secondary"
                        :disabled="!canCleanFilters"
                        @click="clear"
                    >
                        {{ t('clear') }}</b-button
                    >
                    <b-button variant="primary" :disabled="hasFormChanged" @click="apply">
                        {{ t('apply') }}</b-button
                    >
                </b-col>
            </b-form-row>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import isNil from 'lodash/isNil';
import {surgeonIdNameFormat} from '@/utilities/formatters';

const defaultFilters = () => ({
    createdBy: null,
});

export default {
    name: 'OrderNotificationFilters',
    components: {},
    data() {
        return {
            filters: defaultFilters(),
            formState: JSON.stringify(defaultFilters()),
        };
    },

    computed: {
        canCleanFilters() {
            return Object.values(this.filters).some((x) => !isNil(x));
        },
        hasFormChanged() {
            return this.formState == JSON.stringify(this.filters);
        },
    },

    methods: {
        isNil,
        surgeonIdNameFormat,
        formStateCatch() {
            this.formState = JSON.stringify(this.filters);
        },
        filterCatch() {
            this.filters = JSON.parse(this.formState);
        },
        cancel() {
            this.$refs.dropdown.hide(true);
        },
        clear() {
            this.filters = defaultFilters();
            this.apply();
            this.cancel();
        },
        apply() {
            this.$emit('change', this.filters);
            this.formStateCatch();
            this.cancel();
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .w-130px {
    width: 130px;
}
</style>
